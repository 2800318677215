<template>
  <div>
    <div class="space-y-2 py-4">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Productos</span>
        <a class="text-purple-400" @click="showCreateProductDialog">
          <strong>+ Agregar nuevo producto</strong>
        </a>
      </div>
      <div>
        <div class="flex flex-row space-x-2">
          <el-input
            type="text"
            v-model="productTextSearch"
            @input="showProductsDialog(true)">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
          <sa-button
            size="mini"
            type="secondary"
            @click="showProductsDialog(true)">
            Explorar
          </sa-button>
        </div>
      </div>
    </div>

    <table
      class="table-fixed py-4 border-separate"
      v-if="products.length">
      <thead>
      <tr class="text-xs text-gray-400 uppercase">
        <th class="w-5/12 text-left">
          <span>Producto</span>
        </th>
        <th class="w-2/12 text-left">
          <span>Cantidad</span>
        </th>
        <th class="w-2/12 text-right">
          <span class="mr-2">Costo</span>
        </th>
        <th class="w-2/12 text-right">
          <span class="mr-2">Precio</span>
        </th>
        <th class="w-1/12 sr-only">
          Eliminar
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="product in products"
        :key="`orderDeviceProducts-${product.id}`">
        <td class="">
          <div class="w-full flex flex-col">
            <strong class="text-sm">{{ product.productCategory?.name || product.category }}</strong>
            <span>{{ product.name }}</span>
          </div>
        </td>
        <td>
          <el-form
            :ref="`product-${product.id}-quantity`"
            :model="product"
            :rules="productRules">
            <el-form-item prop="quantity">
              <el-input
                v-model="product.quantity"
                type="number"
                placeholder="0" />
            </el-form-item>
          </el-form>
        </td>
        <td>
          <el-form
            :ref="`product-${product.id}-cost`"
            :model="product"
            :rules="productRules">
            <el-form-item prop="cost">
              <el-input
                v-if="$store.state.account.canEditOrdersPrices"
                v-model="product.cost"
                type="number"
                placeholder="0.00" />
              <el-input
                disabled
                v-if="!$store.state.account.canEditOrdersPrices"
                placeholder="***" />
            </el-form-item>
          </el-form>
        </td>
        <td>
          <el-input
            v-model="product.price"
            class="w-full el-input-text-right"
            type="number"
            placeholder="0.00" />
        </td>
        <td class="text-right">
          <sa-icon-button
            iconClass="bx bx-x"
            with-bg
            @click="removeProduct(product.id)" />
        </td>
      </tr>
      </tbody>
    </table>

    <el-dialog
      :visible.sync="isVisibleSearchProductsDialog"
      :width="dialogClientWidth"
      @close="onCloseSearchProductsDialog">
      <div class="-mx-5">
        <device-products-search
          v-if="isVisibleSearchProductsDialog"
          :textSearch="productTextSearch"
          @cancel="showProductsDialog(false)"
          @onSelectProducts="onSelectProducts"
        />
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isVisibleCreateProductDialog"
      append-to-body
      show-close
      :width="dialogClientWidth">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Crear Producto</span>
        <el-button class="el-button--primary" @click="createProduct">Guardar</el-button>
      </div>
      <product-form :loading="createProductLoading" :form="createProductForm" />
    </el-dialog>
  </div>
</template>
<style scoped>
.el-form-item {
  margin-bottom: 0;
}
.is-error {
  margin-bottom: 32px;
}
</style>
<script>
import responsiveSize from '@/mixins/responsiveSize';
import error from '@/mixins/error';
import { mutations, state } from '@/views/app/orders/form/store';
import ProductService from '@/services/ProductService';

export default {
  name: 'ProductsList',
  components: {
    DeviceProductsSearch: () => import('./ProductsSearch.vue'),
    ProductForm: () => import('@/views/app/products/Form.vue'),
  },
  mixins: [
    responsiveSize,
    error,
  ],
  methods: {
    resizeDialogWidth() {
      const percentageWidth = this.getPercentageWidth();
      this.dialogClientWidth = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    showCreateProductDialog() {
      this.isVisibleCreateProductDialog = true;
      this.resizeDialogWidth();
    },
    onCloseSearchProductsDialog() {
      this.productTextSearch = null;
    },
    showProductsDialog(show) {
      this.isVisibleSearchProductsDialog = show;
    },
    onSelectProducts(selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        const product = selectedProducts[i];
        mutations.addOrderProduct({ ...product, quantity: 1 });
      }
      this.showProductsDialog(false);
    },
    async createProduct() {
      this.createProductLoading = true;
      try {
        const request = {
          name: this.createProductForm.name,
          description: this.createProductForm.description,
          category: this.createProductForm.category,
          brandModel: this.createProductForm.brand_model,
          cost: parseFloat(this.createProductForm.cost),
          price: parseFloat(this.createProductForm.price),
          quantity: parseFloat(this.createProductForm.quantity),
        };
        const product = await ProductService.create({ product: request });
        await this.$toastr.s('Se creo con exito');
        mutations.addOrderProduct({ ...product, quantity: 1 });
      } catch(error) {
        this.$toastr.e(this.getErrorMessage(error));
      } finally {
        this.createProductLoading = false;
        this.isVisibleCreateProductDialog = false;
      }
    },
    removeProduct(productId) {
      mutations.removeOrderProduct(productId)
    },
  },
  data() {
    return {
      dialogClientWidth: '40%',
      productTextSearch: null,
      isVisibleSearchProductsDialog: false,
      isVisibleCreateProductDialog: false,
      createProductLoading: false,
      createProductForm: {
        name: undefined,
        description: undefined,
        category: undefined,
        brand_model: undefined,
        cost: undefined,
        price: undefined,
        quantity: 0,
      },
      productRules: {
        quantity: [{
          required: true,
          validator(rule, value, callback) {
            if (value === null || value === undefined || value === '' || value <= 0)
              callback(new Error('La cantidad debe ser mayor a 0 para continuar'));
          },
        }],
        cost: [{
          required: true,
          validator(rule, value, callback) {
            if (value === null || value === undefined || value === '' || value < 0)
              callback(new Error('El costo debe ser 0 o un número mayor para continuar'));
          },
        }],
      },
    }
  },
  computed: {
    products() {
      return state.order.orderProducts;
    },
  },
}
</script>
